import React from "react";
// Customizable Area Start
import "./style.css";
import ReactEcharts from "echarts-for-react"

import {
  Box,
  Popover,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import {
  picture,
  videoCall,
  subarrowicon,
  globe,
  arrow5,
} from "./assets";
import { styled } from '@material-ui/core/styles';
import Chart from "react-apexcharts";
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { getFirstCharacterFromEachWord } from "../../../components/src/commonFn";

// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import ClientList from "../../../components/src/ClientList";
import { Link } from "react-router-dom";
import TransactionFilter from "../../../components/src/TransactionFilter";
let options = {
  tooltip: {
    trigger: "item",
  },

  legend: {
    top: '-1%',
    left: 'center',
    show: false
  },

  series: [
    {
      type: "pie",
      radius: ["80%", "90%"],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 0,
        borderColor: "#fff",
        borderWidth: 3,
      },
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: false,
          fontSize: 40,
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        { value: 1048, name: "Search Engine" },
        { value: 735, name: "Direct" },
        { value: 580, name: "Email" },
        { value: 484, name: "Union Ads" },
        { value: 300, name: "Video Ads" },
      ],
    },
  ],
};

export default class Payment extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  dialogPaperStyle1 = {
    width: 'max-content',
    borderRadius: '12px',
    padding: '19px 23px',
    backgroundColor: '#3c3c51',
    border: '1px solid #2DE2D5',
  };
  renderProfile = () =>{
    return (
      <div  data-test-id="profileLinkPage" style={styles.container} onClick={()=> this.goToProfilePage()}>
      <div>
        {this.state.plannerInfo.image ? (
          <img
            src={this.state.plannerInfo.image}
            style={styles.image}
            alt="profile"
          />
        ) : (
          <div style={styles.noImg}>
            {getFirstCharacterFromEachWord(this.state.plannerInfo.name)}
          </div>
        )}
      </div>
      <div style={styles.textContainers}>
        <span style={styles.nameStyle}>{this.state.plannerInfo.name}</span>
        <span style={styles.email}>{this.state.plannerInfo.email}</span>
      </div>
    </div>
    )
  }

  handleDataPendingModel = () => {
    return (
      <>
        <DialogTitle id="alert-dialog-slide-title" style={{ padding: '15px 40px 0px 40px', textAlign: 'center', }}>
          {
            this.state.IsPendingDataReject === true ?
              <>
                <span style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  color: '#fff',
                }}>Are you sure you want to Decline the request?</span><br />
                <span style={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  textAlign: 'center',
                  color: '#fff',
                  fontSize: '16px',
                }}>Once declined, cannot be accepted.</span></>
              :
              <>
                <span style={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 600,
                  textAlign: 'center',
                  color: '#fff',
                }}>
                  Are you sure you want to accept the request ?
                </span>
              </>
          }

        </DialogTitle>
        <DialogContent style={{
          padding: '0px!important',
          marginBottom: '0px',
          paddingTop: '15px',
        }}>
          <DialogContentText id="alert-dialog-slide-description"
            style={{
              fontFamily: 'Poppins !important',
              fontSize: '20px !important',
              color: 'rgb(255, 255, 255) !important',
              fontWeight: 500,
              marginBottom: '0px',
            }}>
            <div className="modal-button-container">

              <ButtonStyle2 data-test-id="handleCancle" onClick={() => this.dialogModelCl()}>{'Cancel'}</ButtonStyle2>
              {
                this.state.IsPendingDataReject === true ?
                  <>
                    <ButtonStyleDecline className="nameButton" data-test-id="handleDeclining" onClick={() => this.userAcceptDecline(this.state.pendingDataItem, 'rejected')}>{'Decline'}</ButtonStyleDecline>
                  </>
                  :
                  <>
                    <ButtonStyle1 data-test-id="handleAccepting" className="nameButton" onClick={() => this.userAcceptDecline(this.state.pendingDataItem, 'accepted')}>{'Accept'}</ButtonStyle1>
                  </>
              }

            </div>
          </DialogContentText>
        </DialogContent>
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
        let pieChartData = { ...options, series: [{ ...options.series[0], data: this.state.monthlyGoal }] }
    return (
      <div className="pagedashboard-wrapper2">
        <div style={{ justifyContent: 'end' }} className="header-wrapper" >
          <div className="page-info">
            <ul className="drop-menu">
              <li>
              {this.renderProfile()}
              </li>
            </ul>
          </div>
        </div>

        <Grid container spacing={3} >
          <Grid item sm={12} md={8} style={{ width: "100%" }}>
            <div className="faq-wrapper">
              <div
                data-test-id="bankaccounttab"
                className={this.state.expandBank}
                style={{ border: '2px solid rgba(192, 182, 182, 0.1)' }}
              >

                <div className="mixed-chart" style={{ position: "relative" }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4 style={{ color: "white" }}>Earnings Overview</h4>
                    <IncomeOverviewSelect onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.handleIncomeSelectClick(event)} data-test-id="income-overview-dropdown">{this.state.incomeOverviewDuration === this.IncomeOverviewDuration.Monthly ? "Monthly" : "Yearly"}
                      <img style={{ width: '14px' }} src={arrow5} alt="morevert" />
                    </IncomeOverviewSelect>

                    <Popover
                      open={Boolean(this.state.incomeAnchorToolTipEl)}
                      data-test-id="Closepopup"
                      elevation={0}
                      anchorEl={this.state.incomeAnchorToolTipEl}
                      onClose={() => this.closeIncomeOverviewPopover()}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      className="simple-tooltip"
                      PaperProps={{
                        style: {
                          backgroundColor: "transparent",
                          marginTop: "10px"
                        }
                      }}
                    >
                      <Box style={styles.actionContainer}>
                        <Typography style={styles.monthlyIncomeTxt} data-test-id="income-overview-month-duration" onClick={() => this.handleIncomeOverviewDropdown(this.IncomeOverviewDuration.Monthly)}>
                          Monthly
                        </Typography>
                        <Typography style={styles.yearlyIncomeTxt} data-test-id="income-overview-year-duration" onClick={() => this.handleIncomeOverviewDropdown(this.IncomeOverviewDuration.Yearly)}>
                          Yearly
                        </Typography>
                      </Box>
                    </Popover>
                  </div>
                  <Chart
                    data-test-id="income-overview-chart"
                    height={"297px"}
                    options={{
                      chart: {
                        id: "basic-area",
                        toolbar: {
                          show: false,
                        },
                      },
                      xaxis: {
                        categories: this.state.incomeOverviewData.xaxis,
                      },
                      yaxis: {
                        labels: {
                          formatter: this.formatIncomeOverviewValue
                        },
                        tickAmount: 5,
                      },
                      grid: {
                        borderColor: "#1d1d1f",
                        strokeDashArray: 5,
                      },
                      colors: ["#1a697d", "#00E396"],
                      plotOptions: {
                        area: {
                          fillTo: "origin",
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      legend: {
                        position: "top",
                      },
                      stroke: {
                        curve: "smooth",
                      },
                    }}
                    series={[{
                      name: "Income",
                      data: this.state.incomeOverviewData.data,
                    }]}
                    type="area"
                  />

                </div>
              </div>
            </div>
            <div className="faq-wrapper">
              <div
                className={this.state.expandBank}
                data-test-id="bankaccounttab"
                style={{ border: '2px solid rgba(192, 182, 182, 0.1)' }}
              >

                <div style={styles.tableWrapper}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: "center"
                  }}>
                    <h3 style={styles.heading}>Transactions</h3>
                    <TransactionFilter focustList={this.state.focustList} filters={this.state.transactionFilter} handleFilter={this.handleTransactionFilter} activeTab={this.state.activeTab} />

                  </div>

                  <div style={styles.clientTabContainer}>
                  </div>
                  <ClientList goToClientPage={this.goToClientPage} clientServices={[]} activeTab={'payment'} page={this.state.currentListPage} totalRecord={this.state.totalLists||0} rowPerPage={this.state.paymentTransaction?.length} clients={this.state.paymentTransaction||[]} handlePageChange={this.handleTransactionPageChange} />
                </div>
              </div>
            </div>

          </Grid>

          <Grid item sm={12} md={4} className="faq-wrapper" style={{ minHeight: 430, width: "100%" }}>
            <div
              className={this.state.expandBank}
              style={{ border: '2px solid rgba(192, 182, 182, 0.1)', minHeight: 430 }}
              data-test-id="bankaccounttab"
            >
              <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                <h4 style={{ color: 'white',margin:0 }}>Payments Details</h4>
              </div>
              <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                <h5 style={{ color: 'white',margin:0 }}>Billing Address</h5>
                <Link to="/EditPaymentDetails">
                  <p style={{ fontSize: '13px', color: '#814ff9',cursor:"pointer"}}>Edit Details</p>
                </Link>
              </div>
              <p style={{ fontSize: '15px', color: '#ffffff85', margin: 0 }}>{this.state.addAddressFormDetails?.name}
              </p>
              <p style={{ fontSize: '15px', color: '#ffffff85', margin: 0,wordWrap:'break-word',width:'100%' }}>{this.state.addAddressFormDetails?.address} <br/>
              {this.state.addAddressFormDetails?.city} {this.state.addAddressFormDetails?.zip_code}</p>
              <p style={{ fontSize: '15px', color: '#ffffff85', margin: 0 }}>{this.state.addAddressFormDetails?.country}</p>

              <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', marginBottom: "17px" }}>
                <h5 style={{ color: 'white',lineHeight:1,margin:0 }}>Account Details</h5>
                <Link to="/EditAccountInfo">
                  <p style={{ fontSize: '13px', color: '#814ff9',cursor:"pointer" }}>Edit Details</p>
                </Link>
              </div>

              <BankContainer>
                <h5 style={{ color: 'white',marginTop:20 }}>{this.state.addAccountDetails.beneficiary_name}</h5>
                <p style={{ fontSize: '15px', color: '#ffffff85',lineHeight:0}}>{this.formatCardNumber(this.state.addAccountDetails.bank_number)}</p>
              </BankContainer>


            </div>
            <div className="faq-wrapper" >
              <div
                data-test-id="bankaccounttab"
                className={this.state.expandBank}
                style={{ border: '2px solid rgba(192, 182, 182, 0.1)' }}
              >
                <h4 style={{ color: "white" }}>Service Overview</h4>
                <Box>
                  <ReactEcharts
                    option={{...pieChartData,color:this.state.color}}
                  />
                  <Box sx={{ maxWidth: '70%', margin: "auto", width: "100%" }}>
                    {this.state.monthlyGoal.map((i:any) => <Box display={'flex'} alignItems={'center'} mt={2} style={{gap:10}}>
                     
                      <Typography style={{backgroundColor:i.color,width:"15px",height:"15px",border:"1px solid white",borderRadius:"2px"}}></Typography>
                      <Typography style={{color:"white",width:"200px"}}>{i.name}</Typography>
                      <Typography style={{color:"white",opacity:"0.5"}}>{i.percentage}</Typography>

                    </Box>)}


                  </Box>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.openModel}
          keepMounted
          data-test-id="dialogModelCl"
          onClose={this.dialogModelCl}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{ style: this.dialogPaperStyle1 }}
        >
          {
            this.handleDataPendingModel()
          }
        </Dialog>

      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const BankContainer = styled(Box)({
  borderRadius: '25px',
  border: "2px solid white",
  paddingInline: "15px",
  width: "100%",
  height: "120px",
})

const CallContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  borderBottom: "1px solid #00000096",
  color: '#8C8C90',
  fontSize: '11px',
  marginTop: "16px",
})

const CallDetailsWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
  gap: "6%",
  marginBottom: "16px",
})

const CallImage = styled('img')({
  height: "42px",
  width: '42px',
  objectFit: 'cover'
})

const CallClientDetails = styled(Box)({
  color: 'white'
})

const CallClientName = styled(Typography)({
  display: 'grid',
  fontSize: "14px"
})

const CallClientSlot = styled('span')({
  color: '#ffffff78',
  marginTop: "4px",
  fontSize: "12px"
})

const CallJoinBtn = styled('button')({
  height: "32px",
  backgroundColor: "#A7AEC11A",
  color: "#A7AEC1",
  borderRadius: "5px",
  paddingLeft: "12px",
  paddingRight: "12px",
  outline: "none",
  border: "none"
})

const ButtonStyleDecline = styled(Button)({
  fontFamily: "Poppins",
  textAlign: "center",
  borderRadius: "8px",
  border: '1px solid #c44d4d',
  fontSize: "16px",
  color: "#c44d4d",
  fontWeight: 600,
  fontStyle: "normal",
  margin: '10px 10px',
  padding: "5px 30px",
  textTransform: "capitalize",
  '&:hover': {

  },
})

const ButtonStyle1 = styled(Button)({
  borderRadius: "8px",
  color: "#000",
  background: "#C8F9F6",
  textAlign: "center",
  fontSize: "16px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  padding: "5px 30px",
  fontWeight: 600,
  textTransform: "capitalize",
  margin: '10px 10px',
  '&:hover': {
    backgroundColor: "#395D6B",
  },
})

const IncomeOverviewSelect = styled(Box)({
  backgroundColor: 'transparent',
  color: 'white',
  width: '113px',
  appearance: 'none',
  border: '1px solid #f3ecec6e',
  borderRadius: '5px',
  paddingTop: '1px',
  paddingBottom: '1px',
  paddingRight: '9px',
  paddingLeft: '9px',
  justifyContent: "space-between",
  display: 'flex',
  cursor: "pointer",
  alignItems: 'center',
  gap: '10%',
})

const ButtonStyle2 = styled(Button)({
  borderRadius: "8px",
  color: "#fff",
  textAlign: "center",
  border: '1px solid #2DE2D5',
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "capitalize",
  padding: "5px 30px",
  margin: '10px 10px',
  '&:hover': {
  },
})

const styles = {
  expandBank: {
    border: '2px solid rgba(192, 182, 182, 0.1)',
  },
  email: {
    fontSize: "12px",
    marginTop:"-8px",
    color: "#ffffff78",
  },
  nameStyle: {
    fontSize: "14px",
    color: "white",
  },
  faqWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    alignItems: 'center',
  },
  heading: {
    color: 'white',
  },
  tableWrapper: {
    marginLeft: '3%',
    flex: 1,
  },

  columnHeader: {
    color: '#ffffff80',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '3%',
    justifyContent: 'flex-start',
    borderBottom: '1px solid #ffffff78',
    padding: '2%',
    color: 'white',
    fontSize: '13px',
    boxShadow: '0px 3px 0px 0px rgba(10, 150, 150, .19)',
  },
  column: {
    flex: 1,
  },
  actionColumn: {
    textAlign: 'center',
  },
  paginationItem: {
    border: '2px solid rgb(44, 226, 213)',
    borderRadius: '8px',
    paddingRight: '15px',
    paddingLeft: '15px',
    paddingBottom: '2px',
    paddingTop: '2px',
  },
  textContainers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "12px",
  }as React.CSSProperties,
  pagination: {
    display: 'flex',
    alignItems: 'center',
    gap: '3%',
    flexDirection: 'row',
    fontSize: '10px',
    justifyContent: 'end',
    color: '#8C8C90',
  },
  ActiveCallJoinBtn: {
    backgroundColor: "#066DE61A",
    color: "#066DE6"
  },
  dateSelected: {
    backgroundColor: "#1A6882",
  },
  actionContainer: {
    backgroundColor: "#395d6b",
    padding: "1rem",
    borderRadius: "14px",
    zIndex: 999
  },
  noImg: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    color: "#fff",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    backgroundColor: "2CE2D5",
  }as React.CSSProperties,
  yearlyIncomeTxt: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#fff",
    cursor: "pointer",
    marginTop: "1rem",
  },
  image: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
  },
  monthlyIncomeTxt: {
    fontFamily: "Poppins",
    color: "#fff",
    cursor: "pointer",
    fontSize: "14px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  }as React.CSSProperties,
  clientTabContainer: {
    display: 'flex',
    flexDirection: 'row',
    color: '#ffffff80',
    justifyContent: 'flex-start',
    padding: '2% 0px 0px',
    gap: '10%',
    fontSize: '13px',
    borderBottom: '1px solid #ffffff78',
    cursor: "pointer"
  } as React.CSSProperties,
  activeTabStyle: { color: 'white', borderBottom: '3px solid rgb(44, 226, 213)', marginBottom: -1 } as React.CSSProperties,
};
// Customizable Area End