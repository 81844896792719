import React from "react";
// Customizable Area Start
import "./style.css";
import {
  Box,
  Typography,
  Grid,
  Button,
  OutlinedInput,
} from "@material-ui/core";
import {
  plus1,
  subarrowicon,
  picture,
  globe,
} from "./assets";
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getFirstCharacterFromEachWord } from "../../../components/src/commonFn";

// Customizable Area End
import DashboardController, { Props } from "./DashboardController";

export default class EditAccountInfo extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start


  dialogPaperStyle1 = {
    width: 'max-content',
    backgroundColor: '#3c3c51',
    borderRadius: '12px',
    border: '1px solid #2DE2D5',
    padding: '19px 23px',
  };



  handlePendingDataModal = () => {
    return (
      <>
        <DialogTitle id="alert-dialog-slide-title" style={{ padding: '15px 40px 0px 40px', textAlign: 'center', }}>
          {
            this.state.IsPendingDataReject === true ?
              <>
                <span style={{
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: '16px',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                }}>
                  Are you sure you want to Decline the request?
                </span><br />
                <span style={{
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: '16px',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                }}>
                  Once declined, cannot be accepted.
                </span>
              </>
              :
              <>
                <span style={{
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: '16px',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                }}>
                  Are you sure you want to accept the request ?
                </span>
              </>
          }

        </DialogTitle>
        <DialogContent style={{
          padding: '0px!important',
          marginBottom: '0px',
          paddingTop: '15px'
        }}>
          <DialogContentText id="alert-dialog-slide-description"
            style={{
              color: 'rgb(255, 255, 255) !important',
              fontSize: '20px !important',
              fontFamily: 'Poppins !important',
              fontWeight: 500,
              marginBottom: '0px',
            }}>
            <div className="modal-button-container">

              <ButtonStyle2 data-test-id="handleCancel" onClick={() => this.dialogModelCl()}>{'Cancel'}</ButtonStyle2>
              {
                this.state.IsPendingDataReject === true ?
                  <>
                    <ButtonStyleDecline data-test-id="handleDeline" className="nameButton" onClick={() => this.userAcceptDecline(this.state.pendingDataItem, 'rejected')}>{'Decline'}</ButtonStyleDecline>
                  </>
                  :
                  <>
                    <ButtonStyle1 className="nameButton" data-test-id="handleAccept" onClick={() => this.userAcceptDecline(this.state.pendingDataItem, 'accepted')}>{'Accept'}</ButtonStyle1>
                  </>
              }

            </div>
          </DialogContentText>
        </DialogContent>
      </>
    )
  }

  renderProfile = () =>{
    return (
      <div  data-test-id="profileLink" style={styles.container} onClick={()=> this.goToProfilePage()}>
      <div>
        {this.state.plannerInfo.image ? (
          <img
            src={this.state.plannerInfo.image}
            style={styles.image}
            alt="profile"
          />
        ) : (
          <div style={styles.noImg}>
            {getFirstCharacterFromEachWord(this.state.plannerInfo.name)}
          </div>
        )}
      </div>
      <div style={styles.textContainers}>
        <span style={styles.nameStyle}>{this.state.plannerInfo.name}</span>
        <span style={styles.email}>{this.state.plannerInfo.email}</span>
      </div>
    </div>
    )
  } 

  AddForm = () => (
    <Box style={{ marginTop: '24px', paddingRight: '50px', width: '100%', display: "flex", gap: "50px" }}>
      <Box style={{ width: '50%' }}>
        <Typography style={webStyle.customLabel2}>Billing Address</Typography>
        <Box data-test-id="addAddressBtn" style={{ ...webStyle.addressBtn, display: 'flex', alignItems: 'center' }}>
          <img src={plus1} style={{ width: '16px', height: '16px', marginLeft: '10px' }} />
          <Typography style={webStyle.customLabel6}>Add new address</Typography>

        </Box>
      </Box>
      <Box style={{ width: '50%' }}>
        <Typography style={webStyle.customLabel2}>Account Details</Typography>
        <Box data-test-id="addAddressBtn" onClick={() => this.setState({ editAccountStep: "add" })} style={{ ...webStyle.addressBtn, display: 'flex', alignItems: 'center' }}>
          <img src={plus1} style={{ width: '16px', height: '16px', marginLeft: '10px' }} />
          <Typography style={webStyle.customLabel6}>Add account details</Typography>

        </Box>
      </Box>

    </Box>
  )

  render() {
    // Customizable Area Start

    return (
      <StyleContainer className="pagedashboard-wrapper2">

        <div className="header-wrapper" style={{ justifyContent: 'end' }}>

          <div className="page-info">
            <ul className="drop-menu">
              <li>
                {this.renderProfile()}
              </li>
            </ul>
          </div>
        </div>

        <div style={{gap: 30 , display: 'flex'}} >

          <div className="faq-wrapper" style={{height: 300, width: '100%' }}>
            {this.state.editAccountStep !== "" && <div
              className={this.state.expandBank}
              data-test-id="bankaccounttab"
              style={{ border: '2px solid rgba(192, 182, 182, 0.1)' }}
            >

              <div style={{ display: 'flex',alignItems: 'center', justifyContent: "space-between" }}>
                <h4 style={{ color: 'white', margin: '10px' }}>Payments Details</h4>
              </div>
              {this.state.editAccountStep === "initial" ? 
              this.AddForm() :
              <div className="viewPaymentDetail">
              <div className="viewBillingDetails2">
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                  <h5 style={{ color: 'white' }}>Billing Address</h5>
                  <p style={{ fontSize: '13px', color: '#814ff9',cursor:"pointer" }}  data-test-id="goToEdit" onClick={() => this.goToEditPage()}>Edit Details</p>
                </div>
                <div>
                  <p style={{ fontSize: '15px', color: '#ffffff85',lineHeight:1}}>{this.state.addAddressFormDetails.name}</p>
                  <p style={{ fontSize: '15px', color: '#ffffff85',lineHeight:1,wordWrap:'break-word' }}>{this.state.addAddressFormDetails.address} {this.state.addAddressFormDetails.city} {this.state.addAddressFormDetails.zip_code}</p>
                  <p style={{ fontSize: '15px', color: '#ffffff85' ,lineHeight:1}}>{this.state.addAddressFormDetails.country}</p>
                </div>
              </div>
              <div className="viewAccountDetails">
                <BorderBottomBox style={webStyle.address_form} className="form_body">
                  <h5 style={{ color: 'white', marginTop: 10, marginBottom: 10 }}>Account Details</h5>
                  <Grid container spacing={1} className="add_Address_grid">
                    <Grid item xs={12} id="name_label">
                      <Typography style={webStyle.card_input_message}>Beneficiary Name:</Typography>
                      <OutlinedInput
                        className="inputBox"
                        style={{ ...webStyle.inputRootForPayments, marginTop: '6px' }}
                        data-test-id="addNameInput"
                        placeholder="Enter Name"
                        value={this.state.addAccountDetails.beneficiary_name}
                        onChange={(event) => this.updateAccountDetails('beneficiary_name', event.target.value)}
                        fullWidth={true} required
                      />
                    </Grid>
                    <Grid item xs={12} id="addressFieldLabel">
                      <Typography style={webStyle.card_input_message}>Address:</Typography>
                      <OutlinedInput
                        style={{ ...webStyle.inputRootForPayments, marginTop: '6px',width:'100%', wordWrap:'break-word'  }}
                        className="inputBox"
                        fullWidth={true}
                        data-test-id="addAccountAddressInput"
                        value={this.state.addAccountDetails.address}
                        onChange={(event) => this.updateAccountDetails('address', event.target.value)}
                        placeholder="Enter Address" required
                      />
                    </Grid>
                    <Grid item xs={12} id="addressFieldLabel">
                      <Typography style={webStyle.card_input_message}>Account Number:</Typography>
                      <OutlinedInput
                        style={{ ...webStyle.inputRootForPayments, marginTop: '6px' }}
                        className="inputBox"
                        fullWidth={true}
                        data-test-id="addBankNumber"
                        value={this.state.addAccountDetails.bank_number}
                        onChange={(event) => this.updateAccountDetails('bank_number', event.target.value)}
                        placeholder="Enter Account number" required
                      />
                    </Grid>
                    <Grid item xs={12} id="addressFieldLabel">
                      <Typography style={webStyle.card_input_message}>IBAN Number:</Typography>
                      <OutlinedInput
                        style={{ ...webStyle.inputRootForPayments, marginTop: '6px' }}
                        className="inputBox"
                        fullWidth={true}
                        data-test-id="addIbanNumber"
                        value={this.state.addAccountDetails.iban_number}
                        onChange={(event) => this.updateAccountDetails('iban_number', event.target.value)}
                        placeholder="Enter IBAN number" required
                      />
                    </Grid>

                    <Grid item xs={12} style={{display:"flex",gap:"10px",width:"100%", marginTop: 30,flexWrap: "wrap"}}>
                      <Button
                        style={{ ...webStyle.editButton2 }} data-test-id="edit-services-btn" onClick={this.goToPaymentPage}>   
                        <Typography style={{ textTransform: 'none' ,fontFamily: "'Poppins'" }}>Cancel</Typography>
                      </Button>
                      <Button
                        style={{ ...webStyle.editButton }} data-test-id="edit-services-btn1" onClick={this.handleSaveClickConfirm}>   
                        <Typography style={{ textTransform: 'none', fontFamily: "'Poppins'" }}>Save Card</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </BorderBottomBox>
              </div>
              
            </div>}
            </div>}
          </div>
        </div>
        <Dialog
          open={this.state.openModel}
          keepMounted
          data-test-id="dialogModelCl"
          onClose={this.dialogModelCl}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{ style: this.dialogPaperStyle1 }}
        >
          {
            this.handlePendingDataModal()
          }
        </Dialog>
      </StyleContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyleContainer = styled(Box)({
  "& .viewPaymentDetail": {
    display: 'flex', 
    width: '100%'
  },
  "& .viewBillingDetails2": {
    color: 'white', 
    width: "50%", 
    paddingInline: "10px"
  },
  "& .viewAccountDetails": {
    color: 'white', 
    width: "50%", 
    paddingInline: "15px", 
    borderLeft: '2px solid #2CE2D5',
  },
  "@media(max-width: 960px)": {
    "& .viewPaymentDetail": {
      flexWrap: "wrap",
    },
    "& .viewBillingDetails2": {
      width: "100%",
      paddingInline: "10px",
    },
    "& .viewAccountDetails": {
      borderLeft: 'none',
      borderTop: '2px solid #2CE2D5',
      marginTop: "20px",
      paddingTop: "20px",
      width: "100%",
    },
  }
})

const BorderBottomBox = styled(Box)({
  '& input': {
    borderBottom: 'none !important'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#2CE2D5 !important',
  },
})

const ButtonStyleDecline = styled(Button)({
  borderRadius: "8px", textAlign: "center", fontFamily: "Poppins", border: '1px solid #c44d4d', color: "#c44d4d", fontSize: "16px", fontStyle: "normal", fontWeight: 600, padding: "5px 30px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {

  },
})
const ButtonStyle2 = styled(Button)({
  borderRadius: "8px", color: "#fff", border: '1px solid #2DE2D5', textAlign: "center", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, padding: "5px 30px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {

  },
})

const ButtonStyle1 = styled(Button)({
  borderRadius: "8px", background: "#C8F9F6", color: "#000", textAlign: "center", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 600, padding: "5px 30px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {
    backgroundColor: "#395D6B",
  },
})

const styles = {
  faqWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  textContainers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "12px",
  }as React.CSSProperties,
  tableWrapper: {
    flex: 1,
    marginLeft: '3%',
  },
  expandBank: {
    border: '2px solid rgba(192, 182, 182, 0.1)',
  },
  heading: {
    color: 'white',
  },
  nameStyle: {
    fontSize: "14px",
    color: "white",
  },
  email: {
    fontSize: "12px",
    marginTop:"-8px",
    color: "#ffffff78",
  },
  container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  }as React.CSSProperties,
  columnHeader: {
    color: '#ffffff80',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '3%',
    borderBottom: '1px solid #ffffff78',
    padding: '2%',
    fontSize: '13px',
    boxShadow: '0px 3px 0px 0px rgba(10, 150, 150, .19)',
    color: 'white',
  },
  actionColumn: {
    textAlign: 'center',
  },

  column: {
    flex: 1,
  },
  paginationItem: {
    border: '2px solid rgb(44, 226, 213)',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '2px',
    paddingBottom: '2px',
    borderRadius: '8px',
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    gap: '3%',
    color: '#8C8C90',
    fontSize: '10px',
    alignItems: 'center',
  },
  ActiveCallJoinBtn: {
    backgroundColor: "#066DE61A",
    color: "#066DE6"
  },
  dateSelected: {
    backgroundColor: "#1A6882",
  },
  monthlyIncomeTxt: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#fff",
    cursor: "pointer"
  },
  noImg: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    color: "#fff",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    backgroundColor: "2CE2D5",
  }as React.CSSProperties,
  image: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
  },
  actionContainer: {
    backgroundColor: "#395d6b",
    padding: "1rem",
    borderRadius: "14px",
    zIndex: 999
  },
 
 
  clientTabContainer: {
    color: '#ffffff80', display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '2% 0px 0px',
    gap: '10%',
    borderBottom: '1px solid #ffffff78',
    fontSize: '13px',
    cursor: "pointer"
  } as React.CSSProperties,
  yearlyIncomeTxt: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#fff",
    marginTop: "1rem",
    cursor: "pointer"
  },
  activeTabStyle: { color: 'white', marginBottom: -1, borderBottom: '3px solid rgb(44, 226, 213)' } as React.CSSProperties,
};

const webStyle = {
 
  address_form: {
    maxWidth: '340px',
    width: '100%',
    margin: 'auto'
  },
  formHeader: {
    color: 'white',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    margin: '12px 7px',
  },
  
  inputRootForPayments: {
    borderRadius: '10px',
    height: '44px',
    fontFamily: 'Poppins',
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336 !important',
    },
    '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2DF1E3 !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2DF1E3 !important',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2DF1E3 !important',
    },
    '& input::placeholder': {
      fontWeight: 500,
      fontSize: '12px',
      color: 'rgba(255, 255, 255, 0.60)',

    }, '& .MuiInputBase-input': {
      borderColor: '#2DF1E3 !important'
    },
  },

  card_input_message: {
    color: 'white',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300,
    fontFamily: 'Poppins',
  },
  
  editButton2: {
    border: "1px solid #c9f9f6",
    color: "#c9f9f6",
    minWidth: "130px",
    fontSize: "16px",
    fontWeight: 700,
    borderRadius: "8px",
    padding: '10px 0',
    flex:1,
    cursor:"pointer"
  },
  editButton: {
    background: "#c9f9f6",
    borderRadius: "8px",
    color: "#3c3c50",
    minWidth: "130px",
    fontSize: "16px",
    fontWeight: 700,
    padding: '10px 0',
    cursor:"pointer",
    flex:1
  },
  confirmBtnMessage: {
    color: '#C9F9F6',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Poppins',
  },

  customLabel2: {
    color: 'white',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Poppins',
  },
 
  addressBtn: {
    borderRadius: "12px",
    border: '1px solid #928F98',
    width: '100%',
    height: '60px',
    marginTop: '22px',
    cursor: 'pointer',
  },
  customLabel6: {
    color: 'white',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    margin: '12px 12px',
  },
}
// Customizable Area End